<template>
  <div id="components-form-demo-advanced-search">
    <a-form class="ant-advanced-search-form"  :model="listQuery">
      <a-row :gutter="24" style="display:flex;align-items:center">

        <a-col key="1" span="7">
          <a-form-item label="APP">
            <a-select  v-model="listQuery.appId" placeholder="请选择" @change="handleSelectChange">
                <a-select-option v-for="d in body" :key="d.id" :value="d.id" >
                {{ d.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col key="2" span="7">
          <a-form-item label="广告位">
            <a-select v-model="listQuery.adId" placeholder="请选择" allowClear>
              <a-select-option v-for="d in adData" :key="d.id" :value="d.id" >
                {{ d.adName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col key="3">
          <a-form-item label="日期">
            <!-- <a-range-picker @change="onChange" /> -->
            <a-range-picker
              ref="date"
              :placeholder="['起始月份','结束月份']"
              :value="value"
              :mode="mode"
              :format="dateFormat"
              @change="change"
              @panelChange="onChange"
            >
              <template slot="renderExtraFooter">
                <div class="click-sel">
                  <!-- <span>extra footer</span> -->
                  <a-button type="primary" @click="opens" :disabled="!isClick">
                    确定
                  </a-button>
                </div>
              </template>
            </a-range-picker>
            <!-- <a-month-picker :allowClear="false" :default-value="getDateTime(new Date())" :disabled-date="disabledDate" placeholder="月份"  @change="onChange"/> -->
          </a-form-item>
        </a-col>
        <a-col :style="{ textAlign: 'right',paddingTop: '10px'  }">
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <!--          <a-button :style="{ marginLeft: '8px' }" @click="handleReset">清除</a-button>-->
        </a-col>
      </a-row>
    </a-form>
    <div style="margin:15px 0 5px 0;color:#f00">
       <span v-if="statusText">* {{statusText}}</span>
    </div>
    <div class="search-result-list">
      <a-table :columns="columns" :data-source="data" rowKey="id" :pagination="pagination" >

      </a-table>
      <br />
      <a-pagination
          :current="listQuery.current"
          :total="page.total"
          :defaultPageSize="listQuery.size"
          :show-total="total => `共 ${total} 条`"
          @change="affiliatedTableTableChangePage"
      />
      <br />
    </div>
  </div>
</template>
<script>
import moment from 'moment';
const columns1 = [

  { title: 'APP', dataIndex: 'appName', key: 'appName',width: 100, align:"center" },
  // { title: '广告位', dataIndex: 'adName', key: 'adName',width: 100, },
  { title: '时间', dataIndex: 'dateYmd', key: 'dateYmd', width: 150, align:"center" },
  // { title: '月活', dataIndex: 'activity', key: 'askNumber', width: 150, align:"center" },
  { title: '收入', dataIndex: 'showRevenue', key: 'showRevenue', width: 150, align:"center" },
  { title: 'ecpm', dataIndex: 'ecpm', key: 'ecpm', width: 150, align:"center" },
  // { title: '请求量', dataIndex: 'askNumber', key: 'askNumber', width: 150 },
  { title: '曝光量', dataIndex: 'exposureNumber', key: 'exposureNumber', width: 150, align:"center" },
  { title: '点击量', dataIndex: 'clickNumber', key: 'clickNumber', width: 150, align:"center" },
  { title: '点击率', dataIndex: 'clickRate', key: 'clickRate', width: 150, align:"center" },
];
const columns2 = [
  { title: 'APP', dataIndex: 'appName', key: 'appName',width: 100, align:"center" },
  { title: '广告位', dataIndex: 'adName', key: 'adName',width: 100, align:"center" },
  { title: '时间', dataIndex: 'dateYmd', key: 'dateYmd', width: 150, align:"center" },
  // { title: '月活', dataIndex: 'activity', key: 'askNumber', width: 150, align:"center" },
  { title: '收入', dataIndex: 'showRevenue', key: 'showRevenue', width: 150, align:"center" },
  { title: 'ecpm', dataIndex: 'ecpm', key: 'ecpm', width: 150, align:"center" },
  // { title: '请求量', dataIndex: 'askNumber', key: 'askNumber', width: 150 },
  { title: '曝光量', dataIndex: 'exposureNumber', key: 'exposureNumber', width: 150, align:"center" },
  { title: '点击量', dataIndex: 'clickNumber', key: 'clickNumber', width: 150, align:"center" },
  { title: '点击率', dataIndex: 'clickRate', key: 'clickRate', width: 150, align:"center" },
];
import {listAppApi,listAdApi} from '@/api/dayprofit'
import {listApi, checkDelayState, searchList} from '@/api/monthprofit'
import { removeToken } from '@/utils/TokenCache'

export default {
  name: "monthprofit",
  data() {
    return {
      appData:[],
      adData:[],
      mode: ['month','month'],
      value: [],
      curValue:[],
      dateFormat: 'YYYY-MM',
      isClick:false,
      statusText:'',
      listQuery: {
        current: 1,
        size: 5,
        appId:'',
        adId:'',
        sdate:'',
        edate:'',
        // smonth:'',
        // emonth:'',
       //2022.09.09添加 -Seri
        customerId: '', //客户id
        sysType: 1 //系统类型。客户系统默认一种类型，运营系统录入数据时，与该处保持一致
      },
      openDate:false,
      page:{
        total:0,
      },
      form: this.$form.createForm(this),
      data:[],
      columns:columns1,
      pagination:false,

    };
  },
  inject: ['reload'],
  created() {
    let time = new Date()
    let curr = time.getFullYear() + '-' + this.changeTime(time.getMonth() + 1)
    this.listQuery.month = curr
    this.initappData();
  },
  methods: {
    getDateTime(data) {
       return data ? moment(data, 'YYYY-MM') : null
    },
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    /*initappData(){
      let unameDes = sessionStorage.getItem('unameDes')
      this.reload(true)
      listAppApi({}).then(response => {
        this.appData = response.body
        if(this.appData && unameDes === this.appData[0].name){
          this.listQuery.appId = this.appData[0].id
          this.handleSelectChange(this.listQuery.appId)
          this.tableData();
        }
        if(unameDes === !this.appData[0].name) {
          this.$message.error('服务器异常');
          setTimeout(()=>{
            removeToken()
            sessionStorage.removeItem("unameDes")
            this.$router.push({path:'/' });
          },2000)
        }
      })
    },*/
    // 更改登录成功后，获取账号应用列表停滞bug 2022.09.08 -Seri
    initappData(){
      this.reload(true)
      let customerId = localStorage.getItem('customer_id');
      // console.error("App月收益=========== curtomer id: " + customerId);
      if(!customerId){
        this.requestFail('数据缺失，请刷新页面，重新登录重试');
        return;
      }
      this.listQuery.customerId = customerId;
      listAppApi({'customerId': customerId}).then(response => {
        if(!response){
          this.requestFail('服务器无返回数据')
          return;
        }
        this.body = response.body
        // console.error("客户系统 查询账号下所有应用网络请求成功，收到服务器响应：" + this.body)
        if(!this.body){
          this.requestFail('应用列表查询结果为空');
          return;
        }
        /*let name = this.body[0].name;
        // console.error("客户系统 查询账号下所有应用, name: " + name)*/
        this.listQuery.appId = this.body[0].id
        if(!this.listQuery.appId){
          this.requestFail('数据出错 请重新登录');
          return;
        }
        this.handleSelectChange(this.listQuery.appId)
        this.tableData();

      })
    },

    /**
     * 2022.09.09 添加  -Seri
     * 请求失败处理逻辑方法
     * @param errorMsg  错误提示信息
     * */
    requestFail(errorMsg){
      this.reload(false);
      this.$message.error(errorMsg);
    },
    /**
     * 查询按钮
     * */
    handleSearch() {
      this.reload(true)
      this.listQuery.current = 1;
      //进行收益查询，客户id不允许为空！
      if(!this.listQuery.customerId){
        this.requestFail('客户数据异常缺失，无法进行查询');
        return;
      }
      this.searchTableData();
    },
    change() {
       this.isClick = false
       this.curValue = []
       this.value = []
    },
    checkDelays() {
      if(!this.listQuery.customerId || !this.listQuery.appId){
        this.requestFail('数据异常缺失，无法进行查询');
        return;
      }
      checkDelayState(this.listQuery).then(response => {
        // console.log(response)
        this.statusText = response.body.msg
      })
    },
    opens() {
      if(!this.isClick) return
      this.$refs.date.$refs.picker.sOpen = false
      // console.log(moment(this.curValue[0]._d.toLocaleDateString(), this.dateFormat)._i)
      // console.log(moment(this.curValue[1]._d.toLocaleDateString(), this.dateFormat)._i)
      this.value = this.curValue;
    },
    onChange(date, dateString) {
      // console.log(date)
      this.curValue = date;
      this.isClick = true
      let startT = (moment(date[0]._d.toLocaleDateString(), this.dateFormat)._i).split('/')[0] + '-' +
                  this.changeTime((moment(date[0]._d.toLocaleDateString(), this.dateFormat)._i).split('/')[1])
      let startE = (moment(date[1]._d.toLocaleDateString(), this.dateFormat)._i).split('/')[0] + '-' +
                  this.changeTime((moment(date[1]._d.toLocaleDateString(), this.dateFormat)._i).split('/')[1])            
      // console.log(moment(date[0]._d.toLocaleDateString(), this.dateFormat)._i)
      // console.log(moment(date[1]._d.toLocaleDateString(), this.dateFormat)._i)
      // console.log((moment(date[0]._d.toLocaleDateString(), this.dateFormat)._i).split('/'))

      // this.listQuery.smonth = startT
      // this.listQuery.emonth = startE
      //2022.09.09 -Seri
      this.listQuery.sdate = startT
      this.listQuery.edate = startE

      // console.log(dateString)
      // this.$refs.date.$refs.picker.sOpen = false
      // this.value = value;
      // this.mode2 = [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]];
      // this.listQuery.sdate = dateString[0]
      // this.listQuery.edate = dateString[1]
      // console.log(this.listQuery);
    },
    changeTime(data) {
      let time = ''
      if(data<10) {
        time = '0' + data
      } else {
        time = data
      }
      return time
    },
    handleReset() {
      this.listQuery.appId='';
      this.listQuery.adId='';
      this.adData=[];
    },
    handleSelectChange(value) {
      this.adData=[];
      this.listQuery.adId='';
      listAdApi({"appId":value}).then(response => {
        this.adData = response.body
      })
    },
    searchTableData(){
      // console.info(112)
      this.reload(true)
      if(this.listQuery.adId){
        this.columns= columns2;
      }else{
        this.columns= columns1;
      }
      // console.info(11)
      // console.info(this.columns)
      this.checkDelays()
      searchList(this.listQuery).then(response => {
        this.data = response.body.records
        this.page.total = response.body.total
        this.reload(false)
      })
    },
    tableData(){
      // console.info(112)
      this.reload(true)
      if(this.listQuery.adId){
        this.columns= columns2;
      }else{
        this.columns= columns1;
      }
      // console.info(11)
      // console.info(this.columns)
      this.checkDelays()
      listApi(this.listQuery).then(response => {
        this.data = response.body.records
        this.page.total = response.body.total
        this.reload(false)
      })
    },
    affiliatedTableTableChangePage(page,size){
      this.listQuery.current = page;
      this.listQuery.size = size;
      this.tableData();
    }
  }
};
</script>


<style scoped>
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 10px;
}

.click-sel{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}
.click-sel button{
  /* position: absolute;
  right: 0;
  top: 0; */
}
.ant-calendar-range .ant-calendar-footer-extra{
  float: inherit;
}
</style>

<style>
.ant-calendar-range-left .ant-calendar-month-panel-header{
  border-right: 1px solid #e8e8e8;
}
.ant-calendar-range-left .ant-calendar-month-panel-body{
  border-right: 1px solid #e8e8e8;
}
</style>
