import request from '@/utils/request'


export function listApi(data) {
    return request({
        url: '/advert/des/appMonthProfit/listDes',
        method: 'post',
        data
    })
}

export function checkDelayState(data) {
    return request({
        url: '/advert/des/appMonthProfit/checkDelay',
        method: 'post',
        data
    })
}

export function searchList(data) {
    return request({
        url: '/advert/des/appMonthProfit/search',
        method: 'post',
        data
    })
}
